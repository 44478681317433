import React from 'react'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import Timeline from '../../../../components/ui/timeline-ru'
import ImageOne from '../../../../data/images/bg/machines/storage/storage-stock.jpg'
import ImageTwo from '../../../../data/images/bg/machines/storage/storage-components.jpg'
import ImageThree from '../../../../data/images/bg/machines/storage/storage-paste.jpg'
import { SectionWrap } from './style'


const TimelineArea = (props) => {
    const content = [
        {
            year: "АВТОСКЛАДЫ",
            image: ImageOne,
            title: "Управление комплектацией",
            desc: "Интеллектуальное управление складом электронных компонентов становится все более важным фактором успеха в производственных процессах сборки электроники. Управление хранением обеспечивает оптимальную производительность выпуска готовых изделий. Автоматизированные склады электронных компонентов обеспечивают высокую гибкость и эффективность при комплектовании изделий и быстрый переход на выпуск нового продукта, минимизируя затраты времени и человеческий фактор. Эффективная система управления хранением экономит время и деньги в течение всего производственного процесса. Оборудование включает в себя широкий спектр различных решений для автоматизации комплектования и хранения электронных компонентов. Модульная конструкцию и возможность наращивания. Интеллектуальная интеграция с существующими системами хранения.",
            path: "/оборудование-для-монтажа/автоматизированные-системы-хранения/автоматизированные-склады-компонентов",
            lang: "Подробно..."
        },
        {
            year: "СУХОЕ ХРАНЕНИЕ",
            image: ImageTwo,
            title: "Хранение электронных компонентов",
            desc: "Повсеместное внедрение бесвинцовой пайки обнажило проблему хранения электронных компонентов и печатных плат. Повышение температуры пайки с 180°C (свинцовая пайка) до 240°C (бесвинцовая пайка) вызвало увеличение давления паров в корпусах компонентов в три раза. Влажность, которую вбирает в себя корпус электронного компонента, создает давление при пайке, что приводит к микротрещинам под корпусом, разгерметизации корпуса и расслоению печатных плат. Данный дефект не обнаруживается ни при оптической инспекции, ни при проведении электрического тестирования. Корпуса компонентов продолжают разрушатся в процессе эксплуатации и изделие возвращается производителю как рекламация. Решение проблемы – минимизация контакта компонентов чувствительных к влаге, с атмосферой и организация процесса сухого хранения и сушки электронных компонентов на производственном участке.",
            path: "/оборудование-для-монтажа/автоматизированные-системы-хранения/шкафы-сухого-хранения",
            lang: "Подробно..."
        },
        {
            year: "ХРАНЕНИЕ МАТЕРИАЛОВ",
            image: ImageThree,
            title: "Хранение паяльной пасты",
            desc: "Оборудование для безопасного хранения паяльной пасты в контролируемых условиях. Температура от 2 ° C до 20 ° C и варьируются в зависимости от марки паяльной пасты. Срок годности пасты при комнатной температуре составляет примерно 2 недели. Высокие температуры влияют на паяемость и вязкость паяльных паст. Влажность также является важным фактором для паяльных паст. Срок годности паяльных паст, которые не хранятся в надлежащих условиях, сокращается. Это вызывает окисление припоя и загрязнение пасты, что приводит к низкому качеству пайки. По этой причине паяльные пасты следует хранить при низких температурах и в условиях подходящей влажности.",
            path: "/оборудование-для-монтажа/автоматизированные-системы-хранения/хранение-паяльной-пасты",
            lang: "Подробно..."
        }
    ]
    const contentua = [
        {
            year: "АВТОМАТИЧНІ СКЛАДИ",
            image: ImageOne,
            title: "Керування комплектацією",
            desc: "Інтелектуальне керування складом електронних компонентів стає все більш важливим фактором успіху у виробничих процесах збирання електроніки. Керування зберіганням забезпечує оптимальну продуктивність випуску готових виробів. Автоматизовані склади електронних компонентів забезпечують високу гнучкість і ефективність при комплектуванні виробів і швидкий перехід на випуск нового продукту, мінімізуючи витрати часу і людський фактор. Ефективна система керування зберіганням економить час і гроші протягом всього виробничого процесу. Обладнання включає в себе широкий спектр різних рішень для автоматизації комплектування та зберігання електронних компонентів. Модульна конструкцію і можливість нарощування. Інтелектуальна інтеграція з існуючими системами зберігання.",
            path: "/ua/обладнання-для-монтажу/автоматизовані-системи-зберігання/автоматизовані-склади-компонентів",
            lang: "Докладно..."
        },
        {
            year: "СУХЕ ЗБЕРІГАННЯ",
            image: ImageTwo,
            title: "Зберігання електронних компонентів",
            desc: "Широке впровадження безсвинцевої пайки оголило проблему зберігання електронних компонентів і друкованих плат. Підвищення температури паяння з 180 ° C (свинцева пайка) до 240 ° C (безсвинцева пайка) викликало збільшення тиску парів в корпусах компонентів в три рази. Вологість, яку вбирає в себе корпус електронного компонента, створює тиск при паянні, що призводить до мікро тріщин під корпусом, розгерметизації корпусу і розшарування друкованих плат. Даний дефект не виявляється ні при оптичної інспекції, ні при проведенні електричного тестування. Корпуси компонентів продовжують зруйнуються в процесі експлуатації і виріб повертається виробнику як рекламація. Рішення проблеми - мінімізація контакту компонентів чутливих до вологи з атмосферою, організація процесу сухого зберігання і сушки електронних компонентів на виробничих дільницях.",
            path: "/ua/обладнання-для-монтажу/автоматизовані-системи-зберігання/шафи-сухого-зберігання",
            lang: "Докладно..."
        },
        {
            year: "ЗБЕРІГАННЯ МАТЕРІАЛІВ",
            image: ImageThree,
            title: "Зберігання паяльної пасти",
            desc: "Обладнання для безпечного зберігання паяльної пасти в контрольованих умовах. Температура зберігання від 2 ° C до 20 ° C і варіюються в залежності від марки паяльної пасти. Термін придатності пасти при кімнатній температурі становить приблизно 2 тижні. Високі температури впливають на паяємість і в'язкість паяльних паст. Вологість також є важливим фактором для паяльних паст. Термін придатності паяльних паст, що не зберігаються в належних умовах, скорочується. Це викликає окислення припою і забруднення пасти, що призводить до низької якості пайки. З цієї причини паяльні пасти слід зберігати при низьких температурах і в умовах відповідної вологості.",
            path: "/ua/обладнання-для-монтажу/автоматизовані-системи-зберігання/зберігання-паяльної-пасти",
            lang: "Докладно..."
        }
    ]
    if (props.lang === "ru") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Системы управления хранением электронных компонентов предоставляют интеллектуальное и гибкое решение для хранения и помогают полностью сфокусироваться на производстве. Автоматизированные склады имеют гибкую конфигурацию и могут комплектоваться различным дополнительным оборудованием: поддержание влажности и температуры внутри камеры, рентгеновская система подсчета количества компонентов в катушках и др. Возможность последующего наращивания оборудования и конфигурации под специфические требования Заказчика.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={content} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    } else if (props.lang === "uk") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Системи керування зберіганням електронних компонентів надають інтелектуальне і гнучке рішення для зберігання і допомагають повністю сфокусуватися на виробництві. Автоматизовані склади мають гнучку конфігурацію і можуть комплектуватися різним додатковим устаткуванням: підтримка вологості і температури всередині камери, рентгенівська система підрахунку кількості компонентів в котушках і ін. Можливість подальшого нарощування обладнання та конфігурації під специфічні вимоги Замовника.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={contentua} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    }
}

export default TimelineArea
